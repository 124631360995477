:root {
  --primary-color-dark: #45257c;
  --accent-color: #aa8bdc;
  --primary-color-gray: #3e206d;
}

html,
body {
  margin: 0;
  box-sizing: border-box;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.header {
  text-align: center;
  color: var(--primary-color-dark);
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.form-container form {
  margin: 1rem;
}

.form-container input[type="text"] {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid lightgray;
}

.form-container .submit {
  width: 30px;
  border: none;
  background-color: var(--accent-color);
  color: #fff;
}

.form-container .submit:hover {
  background-color: #f6f3fb;
  cursor: pointer;
  color: var(--primary-color-dark);
}

.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.message-container {
  display: flex;
  margin-top: 1rem;
}

.username-box {
  width: 200px;
}

.message-box {
  width: 170px;
}

.post {
  width: 80%;
  max-width: 350px;
  text-justify: left;
  overflow-wrap: break-word;
  max-width: 35ch;
  border-left: 2px solid var(--accent-color);
  margin-top: 2rem;
  padding: 0 1rem 0 1rem;
  -webkit-box-shadow: 0px 5px 2px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 5px 2px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0px 5px 2px 0px rgba(50, 50, 50, 0.1);
}

.post h3 {
  margin-bottom: 0;
  color: var(--primary-color-dark);
}

.post p {
  margin-top: 0;
}

.time {
  font-size: 0.75rem;
  color: var(--accent-color);
}
